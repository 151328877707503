* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  font-family: 'Roboto', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  background: #191919;
}

::-webkit-scrollbar-thumb:hover {
  background: #242424;
}

@supports (scrollbar-color: red blue) {
  *{
      scrollbar-color: #191919 transparent;
      scrollbar-width: thin;
  }
  .mobile {
    scrollbar-color: transparent;
    scrollbar-width: 0;
  }

}

@tailwind base;
@tailwind components;
@tailwind utilities;